<template>
  <vue-notification-list position="top-left" style="z-index:1000; direction:rtl;"></vue-notification-list>
  <Loading v-if="loading"/>
  <div class="nav-container">
    <MainNav/>
  </div>
  <div id="main-router" class="main-router">
    <!-- <div class="home-negishut">
      <img src="@/assets/defaults/accessibility.png" alt="" @click="negishut">
    </div> -->
    <Support v-if="toggle_chat" @capture="capture_snapshot"/>
    <MainModal v-if="modal_comp" :comp="modal_comp"/>
    <router-view/>
  </div>
  <div class="controller-container">
    <UserControllerBar/>
  </div>

  <UpdateSwAlert v-if="show_update_alert"  />
  
</template>

<script>
import {computed, onMounted,onUnmounted, ref} from '@vue/runtime-core';
import store from './store';
import { defineAsyncComponent } from '@vue/runtime-core';
import {update_sw_listenert,show_update_alert} from './Scripts/Update_service_worker'
import  html2canvas from 'html2canvas'
import {set_nutrition} from './Scripts/Catalog/Product'
import useStorage from './Scripts/storage'
import { projectAuth, projectFirestore, projectFunctions } from './firebase/config';
import {store_in_db} from './Scripts/Chat'
import introJs from 'intro.js/intro';
import 'intro.js/introjs.css';
import router from './router';
import Swal from 'sweetalert2';

export default {
  components:{
    UserControllerBar : defineAsyncComponent(() => import('./components/Bars/UserController/UserControllerBar.vue')),
    MainNav : defineAsyncComponent(() => import('./components/Bars/Nav/MainNav.vue')),
    MainModal : defineAsyncComponent(() => import('./components/Overlays/MainModal.vue')),
    UpdateSwAlert:defineAsyncComponent(() => import('./components/Overlays/UpdateSwAlert.vue')),
    Loading:defineAsyncComponent(() => import('./components/Home/Loading.vue')),
    Support:defineAsyncComponent(() => import('./components/Support/Support.vue')),
  },
  setup() {
    const {uploadImage,url} = useStorage()

    const handleBackButtonEvent = (e) => {
      if(store.state.back_path){
        if(store.state.back_path.includes('/')){
          router.push(store.state.back_path)
        }
      }else{
        e.preventDefault();
      }
    }
    //---------------------- init - pars ---------------------------//
    let user = ref(computed(() => {
      return store.state.user
    }));

    const loading = ref(computed(() => {
      return store.state.loading
    }))
    //--------------------------------------------------------------//
    //------------------- init!! web --------------------//
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // Handle screen size change -> Pltaform store
    if(window.innerWidth < 600){
      store.state.platform = 'mobile'
    }else if(window.innerWidth < 900){
      store.state.platform = 'tablet'
    }
    else{
      store.state.platform = 'pc'
    }

    /**
     * Detects if the user is on an iOS device and shows an alert using Swal if true.
     */
    function detectIOSAndShowAlert() {
      const userAgent = window.navigator.userAgent;
      const isIOS = /iPad|iPhone|iPod/.test(userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

      if (isIOS) {
        Swal.fire({
          icon:"warning",
          title: "משתמשי אפל",
          text: "קיימת תקלה טכנית למשתמשי אפל, אנו מודעים ומטפלים בתקלה. נבקש להשתמש במחשב או אנדרואיד בשלב זה."
        });
        return
      }
    }

    //----------------------NODAL--------------------------------------//
    const modal_comp = ref(computed(() => {
      return store.state.modal
    }))
    //-----------------------------------------------------------------//


    // for chat
    const capture_snapshot=()=>{
      html2canvas(document.getElementById('main-router')).then(canvas => {
          canvas.toBlob(async(blob) => {
            await uploadImage(blob,`Chat_messages/${user.value.uid}/${new Date().toLocaleDateString('he')}/${new Date().toLocaleTimeString('he')}`)
            store_in_db(user.value,url.value);
          })
      });
    }
    const toggle_chat = ref(computed(() => {
      return store.state.show_support_chat
    }))

    update_sw_listenert()
    
    onMounted(async() => {
      // detectIOSAndShowAlert();    
      window.addEventListener('popstate', handleBackButtonEvent)
      window.addEventListener('resize', (e) => {
        if(document.activeElement.type && document.activeElement.type === 'text'){
          // console.log("Test input, no resize.")
        }
        else{
          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);

          // Handle screen size change -> Pltaform store
          if(window.innerWidth < 600){
            store.state.platform = 'mobile'
          }else if(window.innerWidth < 900){
            store.state.platform = 'tablet'
          }
          else{
            store.state.platform = 'pc'
          }
        }
      });
    });

    const negishut = () => {
      window.alert("שגיאה בעת משיכת נתונים, אנא עדכן את התמיכה!");
      console.log("Negishut - ERROR! todo: update support");
    }

    onUnmounted(() => {
      window.removeEventListener('popstate', handleBackButtonEvent)
    })

    return{
      user, modal_comp,
      show_update_alert,
      loading,
      toggle_chat,
      capture_snapshot,
      negishut
    }
  }
}
</script>

<style>
@import './CSS/Animations.css';
@import './CSS/ScrollBars.css';
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap");
:root{
  --dark_bg: #262626;
  --ca_bg: #fc6867;
  --atten_bg: linear-gradient(45deg,#fc6867,#fd9265);
  --atten_bg2: linear-gradient(45deg,#1d899c,rgb(24, 95, 112));
  --grad_bg: linear-gradient(45deg,#126782,#023047);
  --grad_bg2: linear-gradient(45deg,#057294,#095283);
  --grad_success: linear-gradient(45deg,#28c76f,#18864a);
  --grad_success2: linear-gradient(45deg,#18864a,#28c76f);
  --turkiz: #126782;
  --turkiz_dark:#023047;
  --dark_bg_turkiz: #011c29;
  --dark_btn: #283046;
  --txt_grey: #37474f;
  --gold:#ebb81f;
  --yellow: #ffb703;
  --orange: #fd9e02;
  --blue: rgb(58, 178, 248);
  --light_pink:#e73c7e;
  --light_orange:#ee7752;
  --light_green:#23d5ab;
  --danger: #ea5455;
  --warning: #f5953b;
  --success: #28c76f;
  --light: #f6f6f6;
  --bgwhite: rgb(242, 242, 242);
  --bgwhite2: rgb(246, 246, 246);
}
html {
  overflow: hidden;
  overscroll-behavior: none;
}
*{
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;  
  box-sizing: border-box;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  font-weight: 300;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.main-router{
  position: relative;
  width: 100%;
  height: calc(calc(var(--vh, 1vh) * 100) - 115px);
  direction: rtl;
  overflow-x: hidden;
  overflow-y: auto;
}
.nav-container{
  width: 100%;
  height: 65px;
  background: var(--bgwhite2);
}
.controller-container{
  width: 100%;
  height: 50px;
}
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
} */
.introjs-tooltip-header{
  text-align: center;
}
.introjs-tooltiptext{
  direction: rtl;
}
.introjs-skipbutton{
  font-size: 18px;
  color: var(--danger);
}
/* .home-negishut{
  position: absolute;
  right: 10px;
  bottom: 5px;
  cursor: pointer;
  z-index: 1000;
} */
.swal2-container {
  direction: rtl;
}
</style>
