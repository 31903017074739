<template>
  <div class="home">
    <div class="overlay-home" v-if="home_slider">
      <div class="home-negishut">
        <img src="@/assets/defaults/accessibility.png" alt="" @click="negishut">
      </div>
      <div class="home-slider">
        <div class="logo-con">
          <img src="../assets/logo/logo_300.png" alt="">
        </div>
        <div class="slog-txt">
          <p>פותחים את השוק</p>
          <p>עם מאות מותגי מזון מובילים</p>
        </div>
        <div class="bus-note">
          <p>בעלי עסקים?</p>
          <span>הצטרפו עוד היום והתחילו לחסוך תוך דקות ספורות!</span>
        </div>
        <div class="continue-shop gr" style="margin-bottom:10px;" @click="go_join">
          <p>הצטרפות בעלי עסקים</p>
        </div>
        <div class="continue-shop" @click="load_catalog">
          <p>המשך לקניות</p>
        </div>
      </div>
    </div>
    <div class="home-catalog">
      <Catalog />
      <div class="cart-pc-container slide-in-left" id="cart-pc-container" v-if="(platform == 'pc' && cart.toggle)">
        <Cart />
      </div>
      <div class="cart-mobile-container slide-in-left"
        v-if="((platform == 'mobile' || platform == 'tablet') && cart.toggle)">
        <Cart />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineAsyncComponent, ref } from '@vue/runtime-core';
import store from '../store';
import { projectFirestore } from '../firebase/config';
import { load_client_catalog, load_user_catalog } from '../Scripts/Catalog'
import router from '../router';
import Swal from 'sweetalert2';
export default {
  name: 'Home',
  components: {
    Catalog: defineAsyncComponent(() => import('../components/Home/Catalog.vue')),
    Cart: defineAsyncComponent(() => import('../components/Cart/CartMain.vue')),
  },
  setup() {
    const home_slider = ref(true);
    const load_catalog = async () => {
      home_slider.value = !home_slider.value
      //check rosClient
      if (store.state.user) {
        if (!store.state.rosClient) {
          const clientsWithPhone = await (await projectFirestore.collection("Clients").where("login_phone", '==', store.state.user.phone.replace('+972', '0')).get()).docs.map(doc => doc.data())
          if (clientsWithPhone.length > 0) {
            store.state.rosClient = clientsWithPhone[0];
            if (store.state.rosClient?.makat) {
              check_orders()
            }
            await load_client_catalog();
          } else {
            load_user_catalog();
          }
        } else {
          if (store.state.rosClient?.makat) {
            check_orders()
          }
          await load_client_catalog();
        }
      } else {
        await load_user_catalog();
      }
    }

    const go_join = () => {
      router.push("/joinb2b")
    }

    const platform = ref(computed(() => {
      return store.state.platform
    }))

    const cart = ref(computed(() => {
      return store.state.cart
    }))

    const check_orders = async () => {
      if (store.state.updating_order) { return }
      const orders = await projectFirestore.collection('Clients').doc(store.state.rosClient.makat).collection('Orders').get()
      let count = 0
      orders.docs.map(doc => {
        if (doc.data().paid == false) {
          count++
        }
      });

      if (count) {
        Swal.fire({
          title: 'הזמנות קיימות',
          text: `קיימות  ${count} הזמנות שטרם שולמו. האם ברצונך לעבור להזמנות הקיימות?`,
          confirmButtonText: 'כן',
          cancelButtonText: 'לא',
          showCancelButton: true
        }).then(async res => {
          if (res.isConfirmed) {
            router.push('/profilepage');
          }
        })
      }
    }

    return {
      platform, cart, home_slider, load_catalog, go_join
    }
  }
}
</script>

<style scoped>
.home {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #F3F4F6;
}

.home-negishut {
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  z-index: 5;
}

.home-catalog {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  transition: 0.3s;
}

.cart-pc-container {
  width: 360px;
  height: 100%;
  background: #F3F4F6;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
}

.cart-mobile-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 100;
  background: #F3F4F6;
}

.overlay-home {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.555);
  z-index: 5;
  display: flex;
  flex-direction: column;
}

.home-slider {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/backs/home-1.png");
  background-position: center;
  background-size: cover;
  flex-direction: column;
}

.continue-shop {
  width: 300px;
  height: 50px;
  font-size: 18px;
  background: linear-gradient(rgba(44, 51, 54), rgba(26, 32, 43));
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 10px;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.589);
  cursor: pointer;
}

.gr {
  background: linear-gradient(rgb(1, 158, 106), rgb(0, 126, 17));
}

.logo-con {
  width: 100%;
  max-width: 300px;
  height: 300px;
  margin-bottom: 25px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.349);
  border-radius: 10px;
}

.bus-note {
  width: 300px;
  height: 80px;
  display: flex;
  flex-direction: column;
  margin: 5px;
  background: linear-gradient(rgba(44, 51, 54, 0.985), rgba(26, 32, 43, 0.95));
  color: white;
  border-radius: 6px;
  padding: 5px;
}

.bus-note p {
  font-size: 18px;
  color: var(--gold);
}

.slog-txt {
  width: 100%;
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: rgb(255, 255, 255);
  background: linear-gradient(rgba(44, 51, 54, 0.685), rgba(26, 32, 43, 0.75));
}

.slog-txt p {
  font-weight: 500;
}
</style>
