import { useNotificationStore } from '@dafcoe/vue-notification'
import Swal from 'sweetalert2'

const slide_pop_error = (text , duration = 2500) => {
    const { setNotification } = useNotificationStore()
    setNotification({
        "message": text,
        "type": "alert",
        "showIcon": true,
        "dismiss": {
          "manually": true,
          "automatically": true
        },
        "duration": duration,
        "showDurationProgress": true,
        "appearance": "light"
      })
}

const slide_pop_successs = (text, duration = 2500) => {
    const { setNotification } = useNotificationStore()
    setNotification({
        "message": text,
        "type": "success",
        "showIcon": true,
        "dismiss": {
          "manually": true,
          "automatically": true
        },
        "duration": duration,
        "showDurationProgress": true,
        "appearance": "light"
      })
}

const slide_pop_info = (text, duration = 2500) => {
    const { setNotification } = useNotificationStore()
    setNotification({
        "message": text,
        "type": "info",
        "showIcon": true,
        "dismiss": {
          "manually": true,
          "automatically": true
        },
        "duration": duration,
        "showDurationProgress": true,
        "appearance": "light"
      })
}

const alert=(icon,title,text)=>{
  return Swal.fire({
      icon,
      title,
      text,
  })
}

const alert_confirm=(title)=>{
  return Swal.fire({
      title,
      icon: 'question',
      iconHtml: '?',
      confirmButtonText: 'כן',
      cancelButtonText: 'לא',
      showCancelButton: true,
      showCloseButton: true,
    })
}



export{slide_pop_error, slide_pop_successs, slide_pop_info,alert,alert_confirm}